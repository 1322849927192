<template>
  <div>
    <Navigation />

    <div class="wrap-box">
      <h3 class="heading">Безбар'єрність</h3>
    </div>
    <div class="wrap-box">
      <div class="gallery-block">
        <template v-if="galleryPhotos.length">
          <div
            v-for="(photo, i) in galleryPhotos"
            :key="i"
            :style="`background-image: url('${photo.url}')`"
            class="gallery-photo"
          ></div>
        </template>
        <p v-else>Тут поки що немає світлин</p>
      </div>
    </div>
  </div>
</template>
<script>
import Navigation from "../components/Navigation";

export default {
  name: "Life",
  components: {
    Navigation
  },
  data: () => ({
    isLoadingContent: false,
    nomoreMediaLeft: false,
    galleryPhotos: [
      // {
      //   url:
      //     "https://cpmsd.com.ua/wp-content/uploads/2019/11/IMG_0622-scaled.jpg"
      // },
      // {
      //   url:
      //     "https://cpmsd.com.ua/wp-content/uploads/2020/06/IMG_5736-scaled.jpg"
      // },
      // {
      //   url:
      //     "https://cpmsd.com.ua/wp-content/uploads/2019/10/TGFQ0321-e1571673005802.jpg"
      // },
      // {
      //   url:
      //     "https://cpmsd.com.ua/wp-content/uploads/2019/10/NACM7844-e1571672982763.jpg"
      // },
      // {
      //   url:
      //     "https://cpmsd.com.ua/wp-content/uploads/2019/10/IMG_8392-e1571672875574.jpg"
      // },
      // {
      //   url:
      //     "https://cpmsd.com.ua/wp-content/uploads/2019/10/MG_8508-e1571672798916.jpg"
      // },
      // {
      //   url:
      //     "https://cpmsd.com.ua/wp-content/uploads/2020/10/IMG_8196-scaled.jpg"
      // },
      // {
      //   url:
      //     "https://cpmsd.com.ua/wp-content/uploads/2020/06/IMG_5421-scaled.jpeg"
      // },
      // { url: "https://cpmsd.com.ua/wp-content/uploads/2020/03/IMG_0578.jpg" }
      // { url: "" },
      // { url: "" },
      // { url: "" },
      // { url: "" }
    ]
  }),
  async created() {
    await this.loadNews(8);
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async handleScroll() {
      if (this.nomoreMediaLeft) return;
      if (this.isLoadingContent) return;
      let clientWidth = document.documentElement.clientWidth;
      let pageYOffset = window.pageYOffset; // height of the current scroll
      let clientHeight = document.documentElement.clientHeight; // height of the window
      let scrollHeight = document.documentElement.scrollHeight; // height of the page

      let needsToLoadMore;
      if (clientWidth < 600) {
        needsToLoadMore = scrollHeight - pageYOffset < clientHeight * 1.75;
      } else {
        needsToLoadMore = scrollHeight - pageYOffset < clientHeight * 1.2;
      }
      if (needsToLoadMore) {
        this.isLoadingContent = true;
        await this.loadNews(4);
        setTimeout(() => {
          this.isLoadingContent = false;
        }, 100);
      }
    },
    async loadNews(newsNumber) {
      const currentPage = Math.ceil(this.galleryPhotos.length / newsNumber);
      const nextPage = currentPage + 1;
      const json = await fetch(
        `/api/v1/media/?sort=-_id&limit=${newsNumber}&page=${nextPage}`
      );
      const { data } = await json.json();

      if (data.length === 0) this.nomoreMediaLeft = true;
      data.forEach(el => this.galleryPhotos.push(el));
    }
  }
};
</script>
<style scoped>
.wrap-box {
  max-width: 1250px;
  width: calc(100% - 30px);
  margin: 0 auto 30px auto;
  padding: 1px 0;
}

h3.heading {
  color: #4e5154;
  margin: 30px auto 30px 40px;
  font-size: 34px;
  text-align: left;
}

h3.heading::after {
  content: " ";
  display: block;
  width: 100%;
  max-width: 200px;
  height: 3px;
  top: 100%;
  left: 0;
  background: #aec3de;
}

.gallery-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: calc(100% - 30px);
  margin: 15px auto;
  height: auto;
  min-height: 30vh;
}

.gallery-photo {
  width: 280px;
  height: 280px;
  background-color: grey;
  display: inline-block;
  margin: 15px auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.3);
}

.gallery-block p {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  height: 30px;
  margin: 10px auto;
}
</style>